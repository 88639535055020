@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --base-white: #fff;
  --base-black: #000;
  --primary-1: #000000a5;
  --primary-2: #000000;
  --primary-3: #cbb0db;
  --primary-4: #f9f0ff;
  --text-1: #1d1d1d;
  --text-2: #393939;
  --text-3: #565656;
  --text-4: #727272;
  --text-5: #8f8f8f;
  --bg-1: #f4f4f4;
  --bg-2: #e9e9e9;
  --bg-3: #f6f6f6;
  --bg-4: #fcfcfd;
  --stroke-1: #f4f4f4;
  --stroke-2: #eaecf0;
  --error: #bb251a;
  --error-bg: #ffe1df;
  --warning: #b54708;
  --warning-bg: #ffe4cd;
  --success: #027a48;
  --success-bg: #e1fff3;
  --tooltip: #202020;
}

* {
  font-family: "Inter", sans-serif;
}

/* buttons - common classes  */

.ant-btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  font-weight: 600;
  height: 40px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.m-icon-btn {
  padding: 8px;
}

.ant-btn.hidden {
  display: none;
}

.m-btn-large {
  height: 48px;
  padding: 12px 16px;
}

/* primary button */

.ant-btn-primary {
  color: var(--base-white);
  background: var(--primary-1);
  border: 1px solid var(--primary-1);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.ant-btn-primary:not(:disabled):hover,
.ant-btn-primary:not(:disabled):active {
  background: var(--primary-2);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.ant-btn-primary:not(:disabled):focus-visible {
  outline: none;
}

.ant-btn-primary:disabled {
  background: var(--primary-3);
  color: var(--base-white);
}

/* default button */

.ant-btn-default {
  color: var(--primary-1);
  border-color: var(--primary-1);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.ant-btn-default:not(:disabled):hover,
.ant-btn-default:not(:disabled):active {
  background: var(--primary-4);
  color: var(--primary-1);
  border-color: var(--primary-1);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.ant-btn-default:not(:disabled):focus-visible {
  outline: none;
}

.ant-btn-default:disabled {
  color: var(--primary-3);
  border-color: var(--primary-3);
  background: var(--base-white);
}

/* text button */

.ant-btn-text {
  color: var(--primary-1);
}

.ant-btn-text:not(:disabled):hover,
.ant-btn-text:not(:disabled):active {
  background: var(--primary-4);
}

.ant-btn-text:disabled {
  color: var(--primary-3);
}

/* menu or nav items */

.ant-menu-vertical {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ant-menu.ant-menu-sub {
  background-color: transparent !important;
}

.ant-menu-vertical .ant-menu-item {
  padding: 8px 12px;
  background: var(--base-white);
  border-radius: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0;
  color: var(--text-3);
  font-weight: 500;
}

.ant-menu-item .ant-menu-title-content {
  margin-inline-start: 0 !important;
}

.ant-menu-vertical .ant-menu-item:hover {
  background-color: var(--bg-3);
}

.ant-menu-item.ant-menu-item-selected {
  background-color: var(--primary-4);
  color: var(--primary-1);
}

.ant-menu-light .ant-menu-submenu-title {
  margin: 0;
  padding: 8px 12px !important;
}

.ant-menu-sub .ant-menu-item-only-child {
  padding-left: 36px !important;
}

.ant-menu-light .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: var(--primary-1);
}

.ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: var(--primary-4) !important;
  color: var(--primary-1);
}

.ant-menu-light .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: var(--bg-3);
}

.ant-menu-light .ant-menu-submenu .ant-menu-submenu-title:active {
  color: var(--primary-1);
  background-color: transparent;
}

.profile-upload .ant-upload {
  overflow: hidden;
}

.phone-input-label::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.phone-input-wrapper input {
  outline: none;
}

.exp-wrapper ul {
  list-style: disc;
}

.exp-wrapper ol {
  list-style: decimal;
}

.m-h1 {
  font-size: 48px;
  letter-spacing: 0em;
}

.m-h2 {
  font-size: 40px;
  letter-spacing: 0.005em;
}

.m-h3 {
  font-size: 32px;
  letter-spacing: 0.0025em;
}

.m-h4 {
  font-size: 24px;
  letter-spacing: 0em;
}

.m-h5 {
  font-size: 20px;
  letter-spacing: 0.005em;
}

.m-h6 {
  font-size: 16px;
  letter-spacing: 0.005em;
}

.m-title1 {
  font-size: 18px;
  letter-spacing: 0.0025em;
}

.m-title2 {
  font-size: 16px;
  letter-spacing: 0.005em;
}

.m-title3 {
  font-size: 14px;
  letter-spacing: 0.01em;
}

.m-body1 {
  font-size: 24px;
  letter-spacing: 0.01em;
}

.m-body2 {
  font-size: 18px;
  letter-spacing: 0.008em;
}

.m-body3 {
  font-size: 16px;
  letter-spacing: 0.005em;
}

.m-body4 {
  font-size: 14px;
  letter-spacing: 0.0025em;
}

.m-body5 {
  font-size: 12px;
  letter-spacing: 0.002em;
}

.m-body6 {
  font-size: 10px;
  letter-spacing: 0.004em;
}

.m-button {
  font-size: 14px;
  letter-spacing: 0.0025em;
}

.m-hyperlink {
  font-size: 14px;
  letter-spacing: 0.0025em;
}

.m-hyperlink-cta {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.0025em;
  text-decoration-line: underline;
}

.m-label1-text {
  width: 27px;
  height: 12px;
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  align-items: center;
  letter-spacing: 0.004em;
  color: #7e39a4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 47px;
  height: 20px;
  background: #f9f0ff;
  border-radius: 16px;
}

@media screen and (min-width: 1024px) {
  .ant-layout-sider.ant-layout-sider-light.min-h-screen {
    width: 264px !important;
    max-width: 264px !important;
    min-width: 264px !important;
  }
}

.m-label {
  font-weight: 500;
  font-size: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  /* width: ; */
  height: 20px;
  border-radius: 16px;
}

.m-label-primary {
  color: var(--primary-1);
  background: var(--primary-4);
}

.m-label-default {
  color: var(--base-black);
  background: var(--base-white);
}

.m-label3-danger {
  background: var(--error-bg);
  color: var(--error);
}

.m-label4-warning {
  background: var(--warning-bg);
  color: var(--warning);
}

.m-label5-success {
  background: var(--success-bg);
  color: var(--success);
}

.m-check {
  border-radius: 16px;
  padding: 4px 8px 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  /* width: 28px; */
  height: 20px;
  border-radius: 16px;
}

.m-check-primary {
  background: var(--primary-4);
  color: var(--primary-1);
}

.m-check-default {
  background: var(--bg-1);
  color: var(--base-black);
}

.m-check-danger {
  background: var(--error-bg);
  color: var(--error);
}

.m-check-warning {
  background: var(--warning-bg);
  color: var(--warning);
}

.m-check-success {
  background: var(--success-bg);
  color: var(--success);
}

.m-circle {
  border-radius: 16px;
  padding: 4px 8px 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  width: 24px;
  height: 24px;
  border-radius: 16px;
}

.m-circle-primary {
  background: var(--primary-4);
  color: var(--primary-1);
}

.m-circle-default {
  background: var(--bg-1);
  color: var(--base-black);
}

.m-circle-danger {
  background: var(--error-bg);
  color: var(--error);
}

.m-circle-warning {
  background: var(--warning-bg);
  color: var(--warning);
}

.m-circle-success {
  background: var(--success-bg);
  color: var(--success);
}

.ant-table-wrapper {
  border: 1px solid var(--stroke-2);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow-x: auto;
  background-color: white;
}

.ant-table-thead .ant-table-cell {
  flex-direction: column;
  align-items: center;
  padding: 16px 24px !important;
  gap: 12px;
  background: var(--bg-3) !important;
}

.ant-table-tbody .ant-table-cell {
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px !important;
  background: #ffffff !important;
}

.ant-card {
  background: #ffffff;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 24px;
}

.m-stepper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 56px;
  padding: 8px 16px 8px 12px;
  border-radius: 4px 4px 0px 0px;
}

.m-stepper-small-text {
  height: 14px;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.004em;
}

.m-stepper-large-text {
  height: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0025em;
}

.m-stepper-bg-primary {
  color: var(--primary-1);
  border-color: var(--primary-1);
  background: var(--primary-4);
  border-bottom-width: 2px;
}

.m-stepper-small-text-primary {
  color: var(--primary-1);
}

.m-stepper-large-text-primary {
  color: var(--primary-1);
}

.m-stepper-bg-default {
  color: var(--base-white);
  /* border-color: var(--base-white); */
  background: var(--base-white);
}

.m-stepper-small-text-default {
  color: var(--text-2);
}

.m-stepper-large-text-default {
  color: var(--text-2);
}

.m-border-text {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0px; */
  /* width: 197px; */
  height: 46px;
  border-radius: 4px 4px 0px 0px;
  /* Inside auto layout */
  /* flex: none; */
  /* order: 0; */
  /* flex-grow: 0; */
}

.avatar-uploader {
  width: 9rem !important;
  padding-left: 1rem;
}

.ant-select-selection-placeholder {
  min-width: 6rem !important;
}

.ant-select-selection-item {
  min-width: 6rem !important;
}

.coach-list-step-table .ant-table-selection-column {
  padding-left: 12px !important;
}

.ant-menu-root > .ant-menu-item {
  padding-left: 8px !important;
  gap: 10px;
}

.paygrov-bg {
  /* background-image: url(../src/assets/paygrov_bg.jpg); */
  background-size: cover;
  background-position: center;
}

.coach-profile-modal .ant-modal-content {
  padding: 0;
}

.competency-score-slider-wrapper .ant-slider .ant-slider-track {
  background-color: var(--primary-1) !important;
}

.competency-score-slider-wrapper .ant-slider-dot.ant-slider-dot-active {
  border-color: var(--primary-1) !important;
}

.competency-score-slider-wrapper .ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px var(--primary-1) !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Set width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primary-1); /* Set color of the thumb */
  border-radius: 4px; /* Round the edges of the thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set color of the track */
  border-radius: 4px; /* Round the edges of the track */
}

.icon-custom-color {
  /* color: red; */
  color: var(--primary-1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.letter {
  opacity: 0;
  display: inline-block;
  animation: fadeIn 0.5s forwards;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.loading-letter {
  animation: blink 3s infinite;
}

@keyframes fadeInPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeInPage 1s forwards;
}

.blinking-button {
  @apply animate-blinking;
}

.code-style {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  padding: 1rem;
  font-family: "Courier New", Courier, monospace;
  color: #00ff00; /* Neon green */
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
}

pre,
code {
  margin: 0;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* body {
  background-color: #181828;
} */

/* #mainCoantiner {
  background-image: url("https://1.bp.blogspot.com/-Fo3iRt97ZXY/XvSG4EMwi0I/AAAAAAAAVEo/mrApRTcVVRk1m-fX9K-ffNwRUXlHUocdwCLcBGAsYHQ/s1600/h.jpg");

  background-color: #181828;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
} */

.mystyleSec {
  background-image: url(http://thepatternlibrary.com/img/h.jpg);
}

.mystylethird {
  background-image: url("https://www.toptal.com/designers/subtlepatterns/patterns/binding_dark.png");
}
/*************header*******/

.main-header {
  top: 0;
  left: 0;
  z-index: 20;
  -webkit-transform: translate3d(0, 0, 0);
  height: 70px;
  width: 100%;
  bottom: inherit;
  text-align: center;
  background: rgba(28, 31, 47, 0.16);
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5),
    -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-width: 1px 0px 0 1px;
}

.folio-btn {
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.03);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  right: 0;
  height: 70px;
  width: 70px;
}

.folio-btn-item {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 15px;
  top: 15px;
}

.folio-btn-dot {
  float: left;
  width: 33.3%;
  height: 33.3%;
  position: relative;
}

.folio-btn-dot:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  border-radius: 50%;
  transition: all 300ms linear;
  transform: scale(1);
}

.folio-btn-dot:before {
  background: #00bcd4;
}

.folio-btn:hover .folio-btn-dot:first-child:before,
.folio-btn:hover .folio-btn-dot:nth-child(3):before,
.folio-btn:hover .folio-btn-dot:nth-child(4):before,
.folio-btn:hover .folio-btn-dot:nth-child(8):before {
  transform: scale(1.8);
  opacity: 0.5;
}

/***social***/
.header-social {
  position: absolute;
  z-index: 20;
  width: auto;
  bottom: 17px;
  right: 90px;
  padding: 0;
  min-width: 140px;
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(255, 255, 255, 0.05);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #2e344d;
}

.header-social:before {
  background: #00bcd4;
}

.header-social:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -3px;
  width: 36px;
  margin-left: -18px;
  height: 6px;
}

.header-social li {
  display: block;
  float: left;
  margin-top: 0;
}

.header-social li a {
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  font-size: 12px;
  border-style: solid;
  color: rgba(255, 255, 255, 0.41);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transition: all 300ms linear;
}

.header-social li a:hover {
  color: #00bcd4;
  cursor: pointer;
}

ul,
li {
  border: none;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

.main-header:before {
  content: "";
  position: absolute;
  left: 10px;
  width: 60px;
  height: 1px;
  bottom: 120px;
  background: rgba(255, 255, 255, 0.1);
}

/***end*****/

.card {
  border: none;
  border-radius: 30px;
  background-color: #14edaa;
}

.wow-bg {
  background-color: #141421;
  border: 1px solid #2e2e4c;
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  margin-top: 4px;
}

.starsec {
  content: " ";
  position: absolute;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
    234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4,
    633px 601px #ff5722, 350px 630px #ffeb3b, 1164px 782px #00bcd4,
    76px 690px #3f51b5, 1825px 701px #cddc39, 1646px 578px #ffeb3b,
    544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
    168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0,
    104px 1690px #8bc34a, 1167px 1338px #e91e63, 345px 1652px #009688,
    1682px 1196px #f44336, 1995px 494px #8bc34a, 428px 798px #ff5722,
    340px 1623px #f44336, 605px 349px #9c27b0, 1339px 1344px #673ab7,
    1102px 1745px #3f51b5, 1592px 1676px #2196f3, 419px 1024px #ff9800,
    630px 1033px #4caf50, 1995px 1644px #00bcd4, 1092px 712px #9c27b0,
    1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
    19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3,
    67px 712px #ff5722, 89px 1406px #f44336, 275px 321px #009688,
    592px 630px #e91e63, 1012px 1690px #9c27b0, 1749px 23px #673ab7,
    94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
    1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4,
    1202px 46px #ff5722, 890px 569px #ff5722, 1613px 813px #2196f3,
    223px 252px #ff9800, 983px 1093px #f44336, 726px 1029px #ffc107,
    1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
    212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff,
    1768px 516px #f44336, 849px 391px #ff9800, 228px 1824px #fff,
    1119px 1680px #ffc107, 812px 1480px #3f51b5, 1438px 1585px #cddc39,
    137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
    857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50,
    1655px 911px #00bcd4, 1102px 1216px #ff5722, 1807px 1044px #fff,
    660px 435px #03a9f4, 299px 678px #4caf50, 1193px 115px #ff9800,
    918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
    108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722,
    1004px 391px #673ab7, 1529px 516px #f44336, 1206px 845px #cddc39,
    347px 583px #009688, 1102px 1332px #f44336, 709px 1756px #00bcd4,
    1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
    320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722,
    1546px 692px #f44336;
  animation: animStar 150s linear infinite;
}

.starthird {
  content: " ";
  position: absolute;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
    234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4,
    633px 601px #ff5722, 350px 630px #ffeb3b, 1164px 782px #00bcd4,
    76px 690px #3f51b5, 1825px 701px #cddc39, 1646px 578px #ffeb3b,
    544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
    168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0,
    104px 1690px #8bc34a, 1167px 1338px #e91e63, 345px 1652px #009688,
    1682px 1196px #f44336, 1995px 494px #8bc34a, 428px 798px #ff5722,
    340px 1623px #f44336, 605px 349px #9c27b0, 1339px 1344px #673ab7,
    1102px 1745px #3f51b5, 1592px 1676px #2196f3, 419px 1024px #ff9800,
    630px 1033px #4caf50, 1995px 1644px #00bcd4, 1092px 712px #9c27b0,
    1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
    19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3,
    67px 712px #ff5722, 89px 1406px #f44336, 275px 321px #009688,
    592px 630px #e91e63, 1012px 1690px #9c27b0, 1749px 23px #673ab7,
    94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
    1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4,
    1202px 46px #ff5722, 890px 569px #ff5722, 1613px 813px #2196f3,
    223px 252px #ff9800, 983px 1093px #f44336, 726px 1029px #ffc107,
    1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
    212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff,
    1768px 516px #f44336, 849px 391px #ff9800, 228px 1824px #fff,
    1119px 1680px #ffc107, 812px 1480px #3f51b5, 1438px 1585px #cddc39,
    137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
    857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50,
    1655px 911px #00bcd4, 1102px 1216px #ff5722, 1807px 1044px #fff,
    660px 435px #03a9f4, 299px 678px #4caf50, 1193px 115px #ff9800,
    918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
    108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722,
    1004px 391px #673ab7, 1529px 516px #f44336, 1206px 845px #cddc39,
    347px 583px #009688, 1102px 1332px #f44336, 709px 1756px #00bcd4,
    1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
    320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722,
    1546px 692px #f44336;
  animation: animStar 10s linear infinite;
}

.starfourth {
  content: " ";
  position: absolute;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
    234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4,
    633px 601px #ff5722, 350px 630px #ffeb3b, 1164px 782px #00bcd4,
    76px 690px #3f51b5, 1825px 701px #cddc39, 1646px 578px #ffeb3b,
    544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
    168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0,
    104px 1690px #8bc34a, 1167px 1338px #e91e63, 345px 1652px #009688,
    1682px 1196px #f44336, 1995px 494px #8bc34a, 428px 798px #ff5722,
    340px 1623px #f44336, 605px 349px #9c27b0, 1339px 1344px #673ab7,
    1102px 1745px #3f51b5, 1592px 1676px #2196f3, 419px 1024px #ff9800,
    630px 1033px #4caf50, 1995px 1644px #00bcd4, 1092px 712px #9c27b0,
    1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
    19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3,
    67px 712px #ff5722, 89px 1406px #f44336, 275px 321px #009688,
    592px 630px #e91e63, 1012px 1690px #9c27b0, 1749px 23px #673ab7,
    94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
    1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4,
    1202px 46px #ff5722, 890px 569px #ff5722, 1613px 813px #2196f3,
    223px 252px #ff9800, 983px 1093px #f44336, 726px 1029px #ffc107,
    1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
    212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff,
    1768px 516px #f44336, 849px 391px #ff9800, 228px 1824px #fff,
    1119px 1680px #ffc107, 812px 1480px #3f51b5, 1438px 1585px #cddc39,
    137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
    857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50,
    1655px 911px #00bcd4, 1102px 1216px #ff5722, 1807px 1044px #fff,
    660px 435px #03a9f4, 299px 678px #4caf50, 1193px 115px #ff9800,
    918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
    108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722,
    1004px 391px #673ab7, 1529px 516px #f44336, 1206px 845px #cddc39,
    347px 583px #009688, 1102px 1332px #f44336, 709px 1756px #00bcd4,
    1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
    320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722,
    1546px 692px #f44336;
  animation: animStar 50s linear infinite;
}

.starfifth {
  content: " ";
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
    234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4,
    633px 601px #ff5722, 350px 630px #ffeb3b, 1164px 782px #00bcd4,
    76px 690px #3f51b5, 1825px 701px #cddc39, 1646px 578px #ffeb3b,
    544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
    168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0,
    104px 1690px #8bc34a, 1167px 1338px #e91e63, 345px 1652px #009688,
    1682px 1196px #f44336, 1995px 494px #8bc34a, 428px 798px #ff5722,
    340px 1623px #f44336, 605px 349px #9c27b0, 1339px 1344px #673ab7,
    1102px 1745px #3f51b5, 1592px 1676px #2196f3, 419px 1024px #ff9800,
    630px 1033px #4caf50, 1995px 1644px #00bcd4, 1092px 712px #9c27b0,
    1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
    19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3,
    67px 712px #ff5722, 89px 1406px #f44336, 275px 321px #009688,
    592px 630px #e91e63, 1012px 1690px #9c27b0, 1749px 23px #673ab7,
    94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
    1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4,
    1202px 46px #ff5722, 890px 569px #ff5722, 1613px 813px #2196f3,
    223px 252px #ff9800, 983px 1093px #f44336, 726px 1029px #ffc107,
    1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
    212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff,
    1768px 516px #f44336, 849px 391px #ff9800, 228px 1824px #fff,
    1119px 1680px #ffc107, 812px 1480px #3f51b5, 1438px 1585px #cddc39,
    137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
    857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50,
    1655px 911px #00bcd4, 1102px 1216px #ff5722, 1807px 1044px #fff,
    660px 435px #03a9f4, 299px 678px #4caf50, 1193px 115px #ff9800,
    918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
    108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722,
    1004px 391px #673ab7, 1529px 516px #f44336, 1206px 845px #cddc39,
    347px 583px #009688, 1102px 1332px #f44336, 709px 1756px #00bcd4,
    1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
    320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722,
    1546px 692px #f44336;
  animation: animStar 80s linear infinite;
}

@keyframes animStar {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-2000px);
  }
}

.logn-btn {
  background: #1c1f2f;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
  box-shadow: 0px 2px 26px rgb(0, 0, 0, 0.5),
    0px 7px 13px rgba(255, 255, 255, 0.03);
  margin-top: 30px;
}

.logn-btn:hover {
  background-color: #1c1f2f;
  border-radius: 50px;
  min-width: 140px;
  /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(255, 255, 255, 0.05);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #2e344d;
}

.textbox-dg {
  background: rgba(28, 31, 47, 0.16);
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #2e344d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5),
    -13px -13px 23px rgba(255, 255, 255, 0.03);
  border-width: 1px 0px 0 1px;
  margin-top: 15px;
}

.form-control:focus {
  border: 1px solid #344d2e;
  color: #495057;
  outline: 0;
  background: rgb(17, 20, 31);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  /* box-shadow: 10px 10px 36px rgb(0,0,0,0.5), -13px -13px 23px rgba(255,255,255, 0.03), inset 14px 14px 70px rgb(0,0,0,0.2), inset -15px -15px 30px rgba(255,255,255, 0.04); */
  box-shadow: 10px 10px 36px rgb(0, 0, 0, 0.5),
    -13px -13px 23px rgba(255, 255, 255, 0.03);
}

.btn-link {
  color: #344d2e;
}

.btn-link:hover {
  color: #2b7a19;
  text-decoration: underline;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #807f7f;
  background-color: transparent;
  border-color: #2b7a19;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 0.2rem rgba(0, 255, 110, 0.25);
}

.mt-6,
.my-6 {
  margin-top: 2rem !important;
}

.socila-btn {
  height: 40px;
  border-radius: 10%;
  width: 40px;
  box-shadow: 3px 9px 16px rgb(0, 0, 0, 0.4),
    -3px -3px 10px rgba(255, 255, 255, 0.06),
    inset 14px 14px 26px rgb(0, 0, 0, 0.3),
    inset -3px -3px 15px rgba(255, 255, 255, 0.05);
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
  margin-right: 10px;
}

.fb-color {
  color: #3b5998;
}

.incolor {
  color: #007bff;
}

.tweetcolor {
  color: #41a4f7;
}
.driblecolor {
  color: #e83e8c;
}

.colorboard {
  color: #00ffaaed;
}

/***********pricing table**********/
.title-h1 {
  margin-top: 0.5em;
  margin-bottom: 1.4em;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: inherit;
  color: #ffffff;
  font-size: 38px;
}

.title-h1 .light {
  font-weight: normal;
}

.pricing-price-wrapper {
  background-color: rgba(71, 71, 72, 0.2);
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  padding-top: 45px;
  padding-bottom: 34px;
  text-align: center;
  box-shadow: 20px 20px 60px rgba(0, 0, 0, 0.13),
    -20px -20px 60px rgba(29, 29, 29, 0.08);
}
.pricing-price {
  background-color: #393b4a;
  background-color: #00bcd4;
  -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 188, 212, 0.31);
  -moz-box-shadow: 0px 0px 0px 10px rgba(0, 188, 212, 0.31);
  box-shadow: 0px 0px 0px 10px rgba(0, 188, 212, 0.31);
  color: #fff;
  border-radius: 50%;
  height: 180px;
  width: 180px;
  display: inline-block;
  padding-top: 46px;
}

.pricing-cost {
  font-size: 50px;
  font-weight: 300;
  line-height: 1;
}

.pricing-table-style-4 .time {
  font-size: 24px;
  font-weight: 300;
}

.pricing-row-title {
  background-color: #303241;
  padding: 26px 10px;
  text-align: center;
}

.pricing_row_title {
  color: #00bcd4;
  line-height: 38px;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.pricing-table.pricing-table-style-4 .pricing-row {
  background-color: #393b4a;
}
.pricing-table-style-4 figure.pricing-row:first-of-type {
  padding-top: 15px;
}
.pricing-table-style-4 .pricing-row {
  padding: 5px 10px;
}
figure {
  margin: 0;
  display: block;
}

figure {
  display: block;
  padding-block-start: 0.4em;
  padding-block-end: 0.4em;
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  background-color: rgba(51, 51, 51, 0.4117647058823529);
  text-align: left;
}

.pricing-row:before {
  vertical-align: middle;
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  color: #00bfa5;
  text-align: center;
  padding-right: 10px;
}

.strike:before {
  content: none;
}
.pricing_row_title {
  color: #00bcd4;
}

body {
  color: #5f727f;
}

.pricing-column {
  float: none;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin-bottom: 42px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.pricing-column-wrapper {
  min-width: 271px;
  text-align: center;
}

.pricing-row-title {
  background-color: rgba(48, 50, 65, 0.2);
}

.margin-body {
  position: relative;
  width: 90%;
  box-sizing: border-box;
  margin: auto;
}

.pricing-row {
  border-top: 1px solid #7273723b;
}

.pricing-price-wrapper {
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  padding-top: 45px;
}

.pricing-column-wrapper + .pricing-column-wrapper .pricing-price {
  background-color: #a637ec;
  -webkit-box-shadow: 0px 0px 0px 10px rgba(166, 55, 236, 0.31);
  -moz-box-shadow: 0px 0px 0px 10px rgba(166, 55, 236, 0.31);
  box-shadow: 0px 0px 0px 10px rgba(166, 55, 236, 0.31);
}

.strike {
  text-decoration: line-through;
  color: #99a9b5;
}

.pricing-column-wrapper + .pricing-column-wrapper .pricing_row_title {
  color: #a637ec;
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing_row_title {
  color: #ee417c;
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing-price {
  background-color: #ee417c;
  -webkit-box-shadow: 0px 0px 0px 10px rgba(238, 65, 124, 0.31);
  -moz-box-shadow: 0px 0px 0px 10px rgba(238, 65, 124, 0.31);
  box-shadow: 0px 0px 0px 10px rgba(238, 65, 124, 0.31);
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing_row_title {
  color: #ff994e;
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing-price {
  background-color: #ff994e;
  -webkit-box-shadow: 0px 0px 0px 10px rgba(255, 153, 78, 0.31);
  -moz-box-shadow: 0px 0px 0px 10px rgba(255, 153, 78, 0.31);
  box-shadow: 0px 0px 0px 10px rgba(255, 153, 78, 0.31);
}

.pricing-column-wrapper + .pricing-column-wrapper .pricing-row:before {
  color: #a637ec;
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing-row:before {
  color: #ee417c;
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing-row:before {
  color: #ff994e;
}

.gem-button {
  cursor: pointer;
  border-width: 2px;
  line-height: 36px;
  border-style: solid;
  background: transparent;
  height: 40px;
  line-height: 40px;
  padding: 0 17px;
  font-size: 14px;
  margin: 25px;
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
}

.gem-button-no {
  cursor:not-allowed;
  border-width: 2px;
  line-height: 36px;
  border-style: solid;
  background: gray;
  height: 40px;
  line-height: 40px;
  padding: 0 17px;
  font-size: 14px;
  margin: 25px;
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
}

.gem-green {
  border-radius: 0px;
  border-color: rgb(0, 188, 212);
  color: rgb(0, 188, 212) !important;
}
.gem-green:hover {
  background-color: rgb(0, 188, 212);
  color: rgb(255, 255, 255) !important;
}

.gem-purpel {
  border-radius: 0px;
  border-color: rgb(166, 55, 236);
  color: rgb(166, 55, 236) !important;
}

.gem-purpel:hover {
  background-color: #a637ec;
  color: rgb(255, 255, 255) !important;
}

.gem-orange {
  border-radius: 0px;
  border-color: #ee417c;
  color: #ee417c !important;
}

.gem-orange:hover {
  background-color: #ee417c;
  color: rgb(255, 255, 255) !important;
}

.gem-yellow {
  border-radius: 0px;
  border-color: #ff994e;
  color: #ff994e !important;
}
.gem-yellow:hover {
  background-color: #ff994e;
  color: rgb(255, 255, 255) !important;
}

.gem-button-position-center {
  text-align: center;
  display: block;
  background-color: rgba(0, 0, 0, 0.16862745098039217);
  border-radius: 0px 0px 10px 10px;
}

.pricing-column:hover .pricing-price-wrapper {
  box-shadow: 0px 35px 80px rgba(4, 255, 236, 0.25),
    0px 0px 0px rgba(10, 197, 173, 0.33);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.pricing-column:hover {
  overflow: hidden;
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing-column:hover
  .pricing-price-wrapper {
  box-shadow: 0px 35px 80px rgba(230, 2, 241, 0.34),
    0px 0px 0px rgba(21, 21, 20, 0.79);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing-column:hover
  .pricing-price-wrapper {
  box-shadow: 0px 35px 80px rgba(255, 4, 166, 0.25),
    0px 0px 0px rgba(222, 6, 84, 0.33);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  + .pricing-column-wrapper
  .pricing-column:hover
  .pricing-price-wrapper {
  box-shadow: 0px 35px 80px rgba(241, 139, 2, 0.34),
    0px 0px 0px rgba(21, 21, 20, 0.79);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
